<template>
  <!-- <iframe
        src="http://sv.shulan.com/watch/10099354?whole"
        style="width: 100%; height: 100%; "
        scrolling="no" allowFullScreen>
    </iframe> -->
  <div class="main2">
    <!-- 适配手机 -->
    <div v-if="isMobile">
      <button v-if="isLogin" class="status-bar-mobile" @click="gotoQus()">
        {{
          isSubmited
            ? "您已完成试题，请继续观看直播（观看须满足总时长的70%以上且考试合格）"
            : "点击登记个人信息并完成试题，用于学分申领（观看须满足总时长的70%以上且考试合格）"
        }}
        <img src="../../assets/img/icon-right.png" alt="" />
      </button>
    </div>
    <!-- 适配pc -->
    <div v-else>
      <button v-if="isLogin" class="status-bar" @click="gotoQus()">
        {{
          isSubmited
            ? "您已完成试题，请继续观看直播（观看须满足总时长的70%以上且考试合格）"
            : "点击登记个人信息并完成试题，用于学分申领（观看须满足总时长的70%以上且考试合格）"
        }}
        <img src="../../assets/img/icon-right.png" alt="" />
      </button>
    </div>

    <div v-if="!isLogin" class="login-container">
      <div class="login-box ">
        <div class="login-title">登录</div>
        <van-field class="choice-input" type='digit' maxlength=11 v-model='phone' rows="1" placeholder="请输入手机号" />
        <van-field class="choice-input" type='digit' maxlength=6 v-model='code' rows="1" placeholder="请输入验证码">
          <template #button>
            <van-button v-if="countdown==0" class="send-btn" @click='sendCode'>{{sendCount > 0 ? '重新发送':'发送验证码'}}</van-button>
            <div v-else class="send-btn" style="color:#9C9FAD">{{countdown}}s</div>
          </template>
        </van-field>

        <div class="desc">根据主办方要求，登录后可以观看直播</div>
        <van-button class="login-btn" @click="login">登 录</van-button>
      </div>
    </div>

    <iframe
      :class="isMobile && isLogin ? 'mobile-iframe' : ''"
      v-if="liveUrl"
      id="ifm"
      :src="liveUrl"
      style="width: 100%; flex: 1; overflow: hidden"
      :scrolling="no"
      allowFullScreen
    >
    </iframe>
  </div>
</template>
<script>
import router from "../../router";
import { Field, Button, Toast, Dialog } from "vant";
import Vue from "vue";
Vue.use(Field);
Vue.use(Button);
Vue.use(Toast);
export default {
  components: {},
  data() {
    return {
      qid: "2022082511375335911102",
      isSubmited: false,
      isMobile: false,
      isLogin: false,
      liveId: "10833315",
      liveUrl: "https://sv.shulan.com/watch/10833315?inset",
      //login：
      countdown: 0,
      sendCount: 0,
      phone: "",
      code: "",
      shareData: {
        title: "慢性肝病全程管理新进展省级继续教育学习班 直播",
        desc: "点击观看大会精彩直播",
        link: window.location.href,
        imgUrl: "https://sho-static.shulan.com/activities/infected/10833315-cover.jpg",
      },
      userSource: 'infection-continuing-edu-class'
    };
  },

  created() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  mounted() {
    console.log("route data");
    this.checkLoginState();
    // document.body.style.overflow = "hidden";
  },
  
  beforeDestroy() {
    document.body.style.overflow = "visible";
    clearInterval(this.timer);
    clearTimeout(this.heartBeat);
    this.exit();
  },
  methods: {
    //login-------
    sendCode() {
      if (!this.$util.checkPhone(this.phone)) {
          return;
      }
     
      Toast.loading({ duration: 0 });
      this.$axios
        .post(`/cuser/send/login/phone/code`, {
          action: "capp_login_or_register_sms",
          phone: this.phone,
        })
        .then((res) => {
          Toast.success({
            message: "发送成功",
          });
          this.sendCount += 1;
          this.countdown = 60;
          this.timer = setInterval(() => {
            this.countdown -= 1;
            if (this.countdown == 0) {
              clearInterval(this.timer);
            }
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          Toast.clear()
        });
    },

    login() {
      if (!this.$util.checkPhone(this.phone)) {
        Toast.fail("请输入正确的手机号");
        return;
      }
      if (this.code.length != 6) {
        Toast.fail("请输入正确的验证码");
        return;
      }

      Toast.loading({ duration: 0 });
      this.$axios
        .post(`/cuser/app/login`, {
          code: this.code,
          phone: this.phone,
        })
        .then((res) => {
          Toast.success({
            message: "登录成功",
          });
          localStorage.setItem("tuid", res.data.userId);
          localStorage.setItem("tu_phone", res.data.phone);
          this.checkLoginState();
        })
        .catch((err) => {
          console.log(err);
          Toast.clear()
        });
    },

    //login-------

    _jssdk() {
      // if (!this.isMobile) return;
      // if (process.env.NODE_ENV !== "production") return;
      const _self = this;
      this.$axios
        .post(
          `/wx/config/params?hospitalId=34-6&url=${decodeURIComponent(
            window.location.href
          )}`,
          {},
          { basurlType: "commonurl" }
        )
        .then((res) => {
          console.log(res);
          wx.config({
            debug: res.data.results.debug,
            appId: res.data.results.appid,
            timestamp: res.data.results.timestamp * 1,
            nonceStr: res.data.results.nonceStr,
            signature: res.data.results.signature,
            jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"],
          });
          wx.ready(function () {
            console.log("wx.ready");
            wx.onMenuShareAppMessage(shareData);
            wx.onMenuShareTimeline(shareData);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkLoginState() {
      this.isLogin =
        localStorage.getItem("tuid") && localStorage.getItem("tuid") != 0;
      if (this.isLogin) {
        this.visitRecord();
        this.checkSubmitState();
      }
    },
    gotoQus() {
      if(!this.isEnd) {
        Dialog.alert({
              confirmButtonColor: "#12BEAE",
              title: "",
              message: '本次会议已结束\n期待下一届会议再相见',
            }).then(() => {});
            return;

      }
      this.$router.push({
        // path: "/activity-infected/questionnaire",
        name: "questionnaire",
        params: {
          visitId: this.visitId,
          userSource: this.userSource,
          qid: this.qid
        },
      });
    },
    gotoThirdLogin() {
      this.$router.push({
        // path: "/activity-infected/questionnaire",
        name: "infectedLogin",
        params: {
          visitId: this.visitId,
        },
      });
      return;
    },
    visitRecord() {
      this.$axios
        .post(`/audio/video/visit/record`, {
          userId: localStorage.getItem("tuid"),
          userSource: this.userSource,
          userPhone: localStorage.getItem("tu_phone"),
          roomId: this.liveId,
        })
        .then((res) => {
          console.log("res", res);
          this.visitId = res.data.visitId;
          this.isEnd = res.data.isEnd;
          this.heartBeat = setTimeout(() => {
            this.heartBeatRecord();
          }, 5000);
        })
        .catch(() => {});
    },
    checkSubmitState() {
      this.$axios
        .get(
          `/questionnaire/record/info/byQuestionnaireIdAndUser?userId=${localStorage.getItem(
            "tuid"
          )}&userSource=${this.userSource}&questionnaireId=${this.qid}`
        )
        .then((res) => {
          if (res.data.questionnaireRecordId) {
            this.isSubmited = true;
          } else {
          }
        })
        .catch(() => {
          Toast.clear();
        });
    },
    heartBeatRecord() {
      this.$axios
        .post(`/audio/video/heartBeat/handle`, {
          visitId: this.visitId,
          type: "WATCHING",
        })
        .then((res) => {
          this.heartBeat = setTimeout(() => {
            this.heartBeatRecord();
          }, 5000);
        })
        .catch(() => {
          this.heartBeat = setTimeout(() => {
            this.heartBeatRecord();
          }, 5000);
        });
    },
    exit() {
      if (!this.isLogin) {
        return;
      }
      this.$axios
        .post(`/audio/video/heartBeat/handle`, {
          visitId: this.visitId,
          type: "WATCH_END",
        })
        .then((res) => {})
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
}
.main2 {
  background: rgb(24, 24, 24);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
}
.status-bar {
  background: #29a3dc;
  width: 100%;
  height: 40PX;
  // top: 0;
  // left: 0;
  // right: 0;
  // position: fixed;
  font-size: 15PX;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor:pointer;
  img {
    width: 17PX;
    height: 17PX;
    margin-left: 4PX;
  }
}

.status-bar-mobile {
    border: none;
  padding: 0 20px;
  background: #12beae;
  // width: 100%;
  height: 88px;
  // top: 0;
  // left: 0;
  // right: 0;
  // position: fixed;
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  cursor:pointer;
  img {
    width: 34px;
    height: 34px;
    margin-left: 8px;
  }
}
.mobile-iframe {
  // margin-top: 88px;
}

.login-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 1000px) {
  .login-box {
    width: 330PX;
    height: 292PX;
    background: #fff;
    border-radius: 16PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .login-title {
    margin: 20PX auto 20PX;
    font-size: 18PX;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #040814;
    line-height: 25PX;
  }
  .choice-input {
    width: 100%;
    padding: 10PX 24PX;
    font-size: 14PX;
    font-weight: 400;
    border-bottom: 1PX solid #eef1f5;
  }
  .send-btn {
    font-size: 15PX;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #12beae;
    line-height: 21PX;
    text-align: right;
    border: none;
    padding: 0 5px;
  }
  .desc {
    margin: 10PX 26PX 17PX;
    font-size: 15PX;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
    line-height: 21PX;
  }
  .login-btn {
    background: #12beae;
    border-radius: 24PX;
    width: 282PX;
    height: 47PX;
    margin: 0 auto;
    text-align: center;
    font-size: 18PX;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20PX;
    display: block;
  }
}

@media only screen and (max-width: 1000px) {
  .login-box {
    width: 560px;
    height: 590px;
    background: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .login-title {
    margin: 40px auto 40px;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #040814;
    line-height: 50px;
  }
  .choice-input {
    width: 100%;
    padding: 20px 44px;
    font-size: 30px;
    font-weight: 400;
    border-bottom: 1px solid #eef1f5;
  }
  .send-btn {
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #12beae;
    line-height: 42px;
    text-align: right;
    border: none;
    padding: 0 5px;
  }
  .desc {
    margin: 29px 40px 38px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
    line-height: 40px;
  }
  .login-btn {
    background: #12beae;
    border-radius: 47px;
    width: 504px;
    height: 94px;
    margin: 0 auto;
    text-align: center;
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    display: block;
  }
}
</style>
